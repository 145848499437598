@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body::-webkit-scrollbar {
  display: none;
}

.slider-flex-div {
  display: flex !important;
  justify-content: space-between !important;
}
/* .................................................ANIMATIONS................................................................ */


.animated-button {
  animation: pulse 1.5s infinite alternate;
}

@keyframes pulse {
  from {
    transform: scale(1.1);
  }
  to {
    transform: scale(1.2);
  }
}


/* .............................. */
@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.fade-in {
  animation: fade-in 1s ease-out;
}

/* .................................... */
@keyframes leftToRight {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes rightToLeft {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.animate-left-to-right {
  animation: leftToRight 1s ease-in-out;
}

.animate-right-to-left {
  animation: rightToLeft 1s ease-in-out;
}

/* ................................... */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.popup-content {
  background: white;
  padding: 10px;
  border-radius: 8px;
  z-index: 10000;
  width: 25%;
}

/* .......................... */
.left-content,
.right-content {
  opacity: 0;
  transform: translateX(-50px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.animate .left-content,
.animate .right-content {
  opacity: 1;
  transform: translateX(0);
}

.blink-text {
  animation: blinker 5s linear infinite;
}

@keyframes blinker {
  50% {
    color: black;
  }
}

/* ....................... */

.scroll-animate {
  overflow: hidden;
  position: relative;
}

.scroll-left {
  animation: scrollLeft 5s ease-in-out infinite;
}
.scroll-right {
  animation: scrollRight 5s ease-in-out infinite;
}

@keyframes scrollLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes scrollRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

/* ............................ */
.water-flow {
  position: relative;
  overflow: hidden;
}

.water-flow::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 160px;
  border-radius: 100px;
  background-image: linear-gradient(120deg, #15a7ad, #15a7ad);
  animation: flowAnimation 2s linear infinite;
  opacity: 0.8;
}

@keyframes flowAnimation {
  0% {
    transform: translateX(-50%) translateY(-50%);
  }
  100% {
    transform: translateX(150%) translateY(-50%);
  }
}

/* ............................... */
@keyframes waterFlow {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.button-container {
  overflow: hidden;
  position: relative;
  background: linear-gradient(90deg, #00bcd4, rgb(28, 51, 66));
  padding: 10px;
  border-radius: 10px;
  animation: waterFlow 4s ease infinite;
  background-size: 300% 300%;
}

button {
  padding: 10px 20px;
  border: none;
  color: white;
  background: none;
  cursor: pointer;
  user-select: none;
}

.flip-card-general {
  background-color: transparent;
  width: 270px;
  height: 270px;
  perspective: 1000px;
}

.flip-card-inner-general {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card-general:hover .flip-card-inner-general {
  transform: rotateY(180deg);
}

.flip-card-front-general,
.flip-card-back-general {
  position: absolute;
  width: 270px;
  height: 270px;
  border-radius: 10px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front-general {
  color: black;
}

.flip-card-back-general {
  background-color: #15a7ad;
  color: white;
  transform: rotateY(180deg);
}

/* ................................. */

.flip-card-general-1 {
  background-color: transparent;
  width: 250px;
  height: 250px;
  perspective: 1000px;
}

.flip-card-inner-general-1 {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card-general-1:hover .flip-card-inner-general-1 {
  transform: rotateY(180deg);
}

.flip-card-front-general-1,
.flip-card-back-general-1 {
  position: absolute;
  width: 250px;
  height: 250px;
  border-radius: 10px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front-general-1 {
  color: black;
}

.flip-card-back-general-1 {
  background-color: #15a7ad;
  color: white;
  transform: rotateY(180deg);
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-slideIn {
  animation: slideIn 0.5s ease-out;
}

.animate-slideInItem {
  animation: slideIn 0.5s ease-out forwards;
  animation-delay: 0.3s;
}

/* ........................... */

.tracking-in-contract-bck-top {
  -webkit-animation: tracking-in-contract-bck-top 1s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) NaNs both;
  animation: tracking-in-contract-bck-top 1s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) NaNs both;
}

@-webkit-keyframes tracking-in-contract-bck-top {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px) translateY(-300px);
    transform: translateZ(400px) translateY(-300px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
@keyframes tracking-in-contract-bck-top {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px) translateY(-300px);
    transform: translateZ(400px) translateY(-300px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;
}

.navbar-set {
  position: fixed;
  top: 32px;
  width: 100%;
  z-index: 5;
}

.bottom-navbar {
  position: fixed;
  top: 102px;
  width: 100%;
  z-index: 5;
}
@keyframes water-flow {
  0% {
    background-color: #393a3b;
    color: white;
  }
  100% {
    background-color: white;
  }
}

.bg-water-animation {
  animation: water-flow 1s infinite alternate;
}
/* ....................................................ANIMATIONS.......................................................... */

.home-center {
  display: flex;
}

.btvn {
  background: linear-gradient(270deg, #15a7ad 0%, #14535e 100.04%);
}

.btny {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.clr {
  border-radius: 21px;
  border: 1px solid rgba(21, 167, 173, 0.1);
  background: rgba(21, 167, 173, 0.61);
  backdrop-filter: blur(0.20000000298023224px);
}
.clr-input {
  background: rgba(36, 195, 201, 0.61);
  border-radius: 21px;
  border: 1px solid rgba(21, 167, 173, 0.1);
  backdrop-filter: blur(0.20000000298023224px);
}

.footer-background-clr {
  background: #212121;
  background: linear-gradient(
    45deg,
    rgba(0, 0, 0, 1) 65%,
    rgba(255, 255, 255, 1) 50%
  );
}
.div-div {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1321px) {
  .footer-background-clr {
    background: #212121;
  }
  .clr-chng-foot {
    color: white;
  }
}
.class-manage-div-logo{
  width: 19%;
  height: auto;
}

@media (max-width: 1617px) {
  .class-manage-div-logo{
    width: 21%;
    height: auto;
    margin-bottom: 30px;
/* margin-top: 3px; */
  }
}

@media (max-width: 1547px) {
  .class-manage-div-logo{
    width: 21%;
    height: auto;
    margin-bottom: 30px;
margin-top: 3px;


  }
}
@media (max-width: 1360px) {
  .class-manage-div-logo{
    width: 22%;
    height: auto;
    margin-bottom: 30px;
margin-top: 5px;


  }
}

@media (max-width: 1210px) {
  .class-manage-div-logo{
    width: 22%;
    height: auto;
    margin-bottom: 30px;
margin-top: 5px;


  }
}
@media (max-width: 1180px) {
  .class-manage-div-logo{
    width: 23%;
    height: auto;
    margin-bottom: 30px;
    margin-top: 5px;

  }
}

@media (max-width: 1146px) {
  .class-manage-div-logo{
    width: 22%;
    height: auto;
    margin-bottom: 30px;
    margin-top: 5px;

  }
}

@media (max-width: 1134px) {
  .class-manage-div-logo{
    width: 23%;
    height: auto;
    margin-bottom: 30px;
    margin-top: 5px;

  }
}


@media (max-width: 1080px) {
  .class-manage-div-logo{
    width: 23%;
    height: auto;
    margin-bottom: 30px;
margin-top: 5px;

  }
}
@media (max-width: 1023px) {
  .bottom-navbar {
    position: fixed;
    top: 99px;
    width: 100%;
    z-index: 5;
  }
  .media-screen-margin-top {
    margin-top: 190px;
  }
}

@media (max-width: 767px) {
  .bottom-navbar {
    position: fixed;
    top: 99px;
    width: 100%;
    z-index: 5;
  }
  .media-screen-margin-top {
    margin-top: 190px;
  }
}
@media (max-width: 640px) {
  .bottom-navbar {
    position: fixed;
    top: 99px;
    width: 100%;
    z-index: 5;
  }
  .navbar-set {
    position: fixed;
    top: 66px;
    width: 100%;
    z-index: 5;
  }
}

@media (max-width: 640px) {
  .navbar-set {
    position: fixed;
    top: 32px;
    width: 100%;
    z-index: 5;
  }
}




@media (max-width:4000px) {
  .div-hide {
    display: none;
  }
}

@media (max-width: 1440px) {
  .div-hide {
    display: none;
  }
  .popup-content {
  
    width: 45%;
  }
}
@media (max-width: 1023px) {
  .div-hide {
    display: block;
  }
  .popup-content {
  
    width: 45%;
  }
}

@media (max-width: 810px) {
  .hide {
    display: none;
  }
  .hide-class-facebook {
    display: none;
  }
  .navbar-set {
    position: fixed;
    top: 32px;
    width: 100%;
    z-index: 5;
  }
  .bottom-navbar {
    position: fixed;
    top: 102px;
    width: 100%;
    z-index: 5;
  }
}




@media (max-width: 680px) {
  .media-classessss {
    display: grid !important;
    justify-content: center !important;
  }
}

@media (max-width: 639px) {
  .navbar-set {
    position: fixed;
    top: 64px;
    width: 100%;
    z-index: 5;
  }
  .bottom-navbar {
    position: fixed;
    top: 119px;
    width: 100%;
    z-index: 5;
  }
  .set-text {
    margin-top: 195px !important;
  }
  .popup-content {
  
    width: 75%;
  }
}

@media (max-width: 396px) {
  
  .popup-content {
  
    width: 100%;
  }
}



